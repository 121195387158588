.sipnotech_privacy-policy_content-main {
    background-color: var(--color-white);
    padding: 2rem;
    margin: 2rem 6rem;
    border-radius: 1rem;
    font-weight: 600;
    display: grid;
    gap: 0.5rem;
}

.sipnotech_privacy-policy_title {
    font-size: 1.2rem;
}

.sipnotech_privacy-policy_bullet {
    margin-inline-start: 2rem;
}

.sipnotech_privacy-policy_desc {
    margin-inline-start: 1rem;
}