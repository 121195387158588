.sipnotech__get-to-work_content-container {
  display: flex;
  align-items: center;
  margin: 4rem 6rem;
  color: var(--color-text);
  position: relative;
  letter-spacing: 0.03em;
  background: var(--color-bg-gradient);
  backdrop-filter: blur(0.428rem);
  border-radius: 2rem;
  z-index: -1;
}


.sipnotech__get-to-work-text_heading {
  position: relative;
  font-size: 3rem;
  font-family: "Poppins";
  font-style: normal;
  line-height: 3.87rem;
  padding: 1rem 0rem;
}

.sipnotech__get-to-work_imageWeb {
  width: 35%;
  padding: 0rem;

}

.sipnotech__get-to-work_btn {
  font-size: 1.3rem;
  padding: 0.8rem 3rem;
  border-radius: 20rem;
  margin-left: 3rem;
}

.sipnotech__get-to-work_blueBlur-left {
  width: 25rem;
  height: 25rem;
  position: absolute;
  left: -10.75rem;
  top: 0rem;
  background: linear-gradient(0deg, var(--color-blue), var(--color-blue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    var(--color-gradientBlue);
  filter: blur(10rem);
  z-index: -5;
}

.sipnotech__get-to-work_blueBlur-right {
  width: 20rem;
  height: 20rem;
  position: absolute;
  right: 0rem;
  top: 10rem;
  background: linear-gradient(0deg, var(--color-blue), var(--color-blue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    var(--color-gradientBlue);
  filter: blur(10rem);
  z-index: -5;
}

@media screen and (max-width: 1000px) {


  .sipnotech__get-to-work-text_heading {
    font-size: 2rem;
    line-height: 2.5rem;
    padding: 0rem;
  }

  .sipnotech__get-to-work_btn {
    font-size: 0.9rem;
    padding: 0.6rem 2rem;
    margin: 0.5rem 0rem;
  }

  .sipnotech__get-to-work_blueBlur-right {
    display: none;
  }

  .sipnotech__get-to-work_blueBlur-left {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .sipnotech__get-to-work_content-container {
    padding: 1rem;
    margin: 2rem 2rem;
    flex-direction: column;
    text-align: center;
  }
  .sipnotech__get-to-work-text_heading {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .sipnotech__get-to-work_btn {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
    margin-left: 1.5rem;
  }
  .sipnotech__get-to-work_imageWeb {
    width: 80%;
    padding: 0rem;
  
  }
  
}