.sipnotech__paragraph {
    padding: 0rem 4rem 4rem 4rem;
    color: var(--color-white);
    letter-spacing: 0.02rem;
}

.sipnotech__paragraph_heading {
    font-size: 3rem;
    color: var(--color-orange);
    font-weight: 600;
    padding: 1rem 0;
}

.sipnotech_paragraph_content-div {
    display: grid;
    gap: 2rem;
}

.sipnotech_paragraph_para {
    line-height: 3rem;
    font-size: 1.5rem;
}

@media screen and (max-width:850px) {
    .sipnotech__paragraph {
        padding: 0rem 1rem 2rem 1rem;
        text-align: center;
    }
    
.sipnotech_paragraph_para {
    line-height: 2rem;
    font-size: 1.1rem;
}

    .sipnotech__paragraph_heading {
        font-size: 2rem;
        color: var(--color-orange);
        font-weight: 600;
        padding: 1rem 0;
    }
}