.sipnotech__contact-forms_container {
  display: flex;
  font-family: var(--font-family);
  color: var(--color-text);
  padding: 3rem 4rem;
  gap: 3rem;
  height: auto;
  letter-spacing: 0.02em;
  position: relative;
}
.sipnotech__contacts-forms_forms-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 3.5rem 5rem;
  gap: 3rem;
  background-color: var(--color-forms-bg);
  border-radius: 1rem;
}
.sipnotech__contacts-forms_contactBoxes-container {
  width: 40%;
}
.sipnotech__contacts-forms_forms_name-container,
.sipnotech__contacts-forms_forms_email-container,
.sipnotech__contacts-forms_forms_mobile-container,
.sipnotech__contacts-forms_forms_message-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.sipnotech__contacts-forms_forms-heading {
  font-size: 2.8rem;
  font-weight: 600;
}
.sipnotech__contacts-forms_forms_inputs {
  background-color: var(--color-forms-bg);
  border: 0.13rem solid var(--color-input-border);
  box-shadow: none;
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  color: var(--color-text-grey);
  font-size: 1.5rem;
}
.sipnotech__contacts-forms_labels {
  font-size: 1.75rem;
  background-color: var(--color-forms-bg);
  z-index: 10;
  padding: 0rem 0.5rem;
  position: absolute;
  top: -1.8rem;
  left: 1rem;
}
.sipnotech__contacts-forms_forms_text-message-input {
  padding: 2rem 1rem 6rem 1rem;
}
.sipnotech__contacts-forms_forms_inputs:focus {
  box-shadow: none;
  outline: none;
}

.sipnotech__contacts-forms_forms_inputs::placeholder {
  font-size: 1.2rem;
}
.sipnotech__contacts-forms_submit-btn {
  padding: 1rem 6rem;
  margin-inline: auto;
  border-radius: 1.5rem;
  font-size: 1rem;
  font-family: var(--font-family);
}
.sipnotech__contacts-forms_contactBoxes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0rem;
}
.sipnotech__contacts-forms_contactBox {
  background: var(--color-bg-gradient);
  border-radius: 2.5rem;
  text-align: center;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 17rem;
}
.sipnotech__contacts-forms_contactBox_title {
  font-size: 2.5rem;
  font-weight: 500;
}
.sipnotech__contacts-forms_contactBox_text {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  padding: 0rem 0.5rem;
}
.sipnotech__contacts-forms_contactBox_phoneNo {
  color: var(--color-orange);
  font-size: 2rem;
  font-weight: 600;
}
.sipnotech__contacts-forms_contactBox_email {
  color: var(--color-orange);
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: underline;
}
.sipnotech__contact-forms_blueBlur {
  width: 25rem;
  height: 25rem;
  position: absolute;
  left: -10.75rem;
  top: 30%;
  background: linear-gradient(0deg, var(--color-blue), var(--color-blue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    var(--color-gradientBlue);
  filter: blur(14rem);
  z-index: -5;
}
.sipnotech__contact-forms_purpleBlur {
  position: absolute;
  width: 30rem;
  height: 30rem;
  right: 25rem;
  top: 20%;
  background: linear-gradient(0deg, var(--color-purple), var(--color-purple)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    var(--color-gradientBlue);
  filter: blur(14rem);
  z-index: -5;
}
@media screen and (max-width: 1000px) {
  .sipnotech__contact-forms_container {
    height: auto;
    padding: 2rem 2.5rem;
  }
  .sipnotech__contacts-forms_forms-container {
    padding: 3.5rem 3rem;
  }
  .sipnotech__contacts-forms_forms-heading {
    font-size: 1.8rem;
  }
  .sipnotech__contacts-forms_forms_inputs {
    padding: 1rem 0.5rem;
  }
  .sipnotech__contacts-forms_forms_text-message-input {
    padding: 2rem 1rem 5rem 1rem;
  }
  .sipnotech__contacts-forms_submit-btn {
    padding: 0.8rem 4rem;
    margin: 0;
    margin-inline: auto;
  }
  .sipnotech__contacts-forms_contactBox {
    padding: 1.5rem 0.5rem;
    height: 14rem;
  }
  .sipnotech__contacts-forms_contactBox_title {
    font-size: 1.5rem;
  }
  .sipnotech__contacts-forms_contactBox_text {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    padding: 0rem 0.5rem;
  }
  .sipnotech__contacts-forms_contactBox_phoneNo {
    font-size: 1.5rem;
  }
  .sipnotech__contacts-forms_contactBox_email {
    font-size: 1rem;
  }
  .sipnotech__contact-forms_blueBlur {
    width: 25rem;
    height: 25rem;
    left: -10.75rem;
    top: 30%;
  }
  .sipnotech__contact-forms_purpleBlur {
    width: 30rem;
    height: 30rem;
    right: 25rem;
    top: 20%;
  }
}

@media screen and (max-width: 800px) {
  .sipnotech__contact-forms_container {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  .sipnotech__contacts-forms_forms-container {
    width: 100%;
    padding: 2.5rem 1.5rem;
    gap: 2rem;
  }
  .sipnotech__contacts-forms_contactBoxes-container {
    width: 100%;
  }
  .sipnotech__contacts-forms_forms-heading {
    font-size: 1.5rem;
  }
  .sipnotech__contacts-forms_forms_inputs {
    font-size: 1rem;
  }
  .sipnotech__contacts-forms_forms_text-message-input {
    padding: 2rem 1rem 4rem 1rem;
  }
  .sipnotech__contacts-forms_labels {
    font-size: 1.2rem;
    top: -1.1rem;
  }
  .sipnotech__contacts-forms_forms_inputs::placeholder {
    font-size: 1rem;
  }
  .sipnotech__contacts-forms_submit-btn {
    padding: 0.7rem 3rem;
  }
  .sipnotech__contacts-forms_contactBoxes-container {
    padding: 1rem 2rem;
    gap: 2rem;
  }
  .sipnotech__contacts-forms_contactBox {
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    height: 12rem;
  }
  .sipnotech__contacts-forms_contactBox_title {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .sipnotech__contacts-forms_contactBox_text {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .sipnotech__contacts-forms_contactBox_phoneNo {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .sipnotech__contacts-forms_contactBox_email {
    color: var(--color-orange);
    font-size: 0.9rem;
    font-weight: 600;
  }
}
