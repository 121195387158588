@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Pacifico&family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;1,200&family=Uchen&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --color-bg: #1a1a23;
  --color-purple: #bf5af2;
  --color-orange: #f68b37fc;
  --color-white: #ffffff;
  --color-text: #ffffff;
  --color-blue: #3486b5;
  --color-gradientBlue: #4093c1;
  --color-text-grey: #c8cdd0;
  --color-light-orange: rgba(246, 139, 55, 0.4);
  --color-bg-grey: #6f6f80;
  --color-bg-grey-bg1: #ffffff38;
  --color-bg-grey-bg2: #ffffff23;
  --color-bg-grey-bg3: #f2f2f21a;
  --color-counter-text: #0f2a3d;
  --color-forms-bg: #363642;
  --color-input-border: #eff3f5;
  --color-bg-gradient: linear-gradient(
    113.28deg,
    rgba(255, 255, 255, 0.4) -13.95%,
    rgba(255, 255, 255, 0.1) 86.85%
  );
}
