.sipnotech__why-us_main {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  font-family: var(--font-family);
  letter-spacing: 0.02em;
  padding: 2rem 2rem;
  text-align: center;
  position: relative;
}
.sipnotech__why-us_header {
  padding: 0rem 0rem 3rem 0rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sipnotech__why-us_main-title {
  font-size: 3rem;
}
.sipnotech__why-us_main-text {
  width: 50rem;
  margin-inline: auto;
}
.sipnotech__why-us_categories_container {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  font-size: 1.5rem;
  margin-inline: 1rem;
}
.sipnotech__why-us_categories {
  width: 26%;
  font-size: 2.2rem;
  white-space: nowrap;
}
.sipnotech__why-us_categories:hover {
  border-bottom: 1.5px solid var(--color-bg-grey);
  cursor: pointer;
}

.sipnotech__why-us_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem;
}
.sipnotech__why-us_text-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 60%;
  text-align: start;
  padding: 2rem 2rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
}
.sipnotech__why-us_text-para {
  max-width: 60rem;
}
.sipnotech__why-us_features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.sipnotech__why-us_feature {
  display: flex;
  align-items: center;
}
.sipnotech__why-us_checkIcon {
  color: var(--color-orange);
  font-size: 1.5rem;
  margin-inline-end: 0.5rem;
  padding: 0rem;
  margin-top: 0.5rem;
}
.sipnotech__why-us_img-container-web {
  width: 40%;
}
.sipnotech__why-us_img-container-mobile {
  display: none;
}
.sipnotech__why-us_img {
  width: 40%;
  padding: 0rem;
}

.sipnotech__why-us_purpleBlur {
  position: absolute;
  width: 30rem;
  height: 30rem;
  right: 6rem;
  top: 25rem;
  background: linear-gradient(0deg, var(--color-purple), var(--color-purple)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    linear-gradient(0deg, var(--color-gradientBlue), var(--color-gradientBlue)),
    var(--color-gradientBlue);
  filter: blur(15rem);
  z-index: -5;
}

@media screen and (max-width: 950px) {
  .sipnotech__why-us_main {
    padding: 2rem 3rem;
  }
  .sipnotech__why-us_header {
    align-items: center;
  }
  .sipnotech__why-us_main-title {
    font-size: 2rem;
  }
  .sipnotech__why-us_main-text {
    width: auto;
  }
  .sipnotech__why-us_categories_container {
    padding: 0rem;
    margin-inline: 0.5rem;
  }
  .sipnotech__why-us_categories {
    font-size: 1rem;
    white-space: unset;
  }
  .sipnotech__why-us_content {
    flex-direction: column;
  }
  .sipnotech__why-us_text-container {
    gap: 2rem;
    width: 100%;
    text-align: center;
    padding: 1.5rem 0.8rem;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .sipnotech__why-us_features {
    gap: 0.5rem;
    font-size: 0.9rem;
  }
  .sipnotech__why-us_checkIcon {
    font-size: 1.3rem;
  }
  .sipnotech__why-us_img-container-mobile {
    display: block;
    width: 100%;
  }
  .sipnotech__why-us_img-container-web {
    display: none;
  }
  .sipnotech__why-us_img {
    width: 20%;
  }

  .sipnotech__why-us_purpleBlur {
    width: 25rem;
    height: 25rem;
    right: auto;
    left: auto;
    top: 15rem;
    filter: blur(10rem);
  }
}
@media screen and (max-width: 600px) {
  .sipnotech__why-us_main {
    padding: 2rem 0.5rem;
  }
  .sipnotech__why-us_img {
    width: 35%;
  }
}
