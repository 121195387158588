.sipnotech__what-we-do_cards-card {
  background: transparent;
  padding: 1.5rem;
  border: 0.05rem solid var(--color-text-grey);
  border-radius: 25px;
  margin: 1rem;
  height: 22rem;
  max-width: 22rem;
  overflow: hidden;
}

.sipnotech__what-we-do_cards-card:hover {
  background: var(--color-bg-gradient);
  transform: translateY(0.2rem);
}

.sipnotech__what-we-do_cards-card_img {
  width: 5rem;
}

.sipnotech__what-we-do {
  color: var(--color-text);
  font-family: var(--font-family);
  letter-spacing: 0.02em;
  padding: 5rem;
}

.Sipnotech__what-we-do_heading {
  font-size: 3rem;
  line-height: 4rem;
  padding: 2rem 0rem;
  font-weight: 600;
}

.sipnotech__what-we-do_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.sipnotech__what-we-do_cards_title {
  font-size: 1.5rem;
  padding: 0.1rem 0rem;
}

.sipnotech__what-we-do_cards_desc {
  font-size: 0.875rem;
  color: var(--color-text-grey);
  max-width: 20rem;
}

.sipnotech__what-we-do_cards-btn {
  background: transparent;
  padding: 1rem 0rem;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  letter-spacing: 0.03em;
}

.sipnotech__what-we-do_cards-btn_icon {
  color: var(--color-text);
  font-size: 1.2rem;
  position: absolute;
  top: 0.8rem;
  padding: 0rem 0.1rem;
}

.sipnotech__what-we-do_cards_first-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
}

.sipnotech__what-we-do_cards_second-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
}

.sipnotech__what-we-do_cards_third-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.sipnotech__what-we-do_people-img {
  max-width: 100%;

}

.sipnotech__what-we-do_people-img-mobile {
  display: none;
}

@media screen and (max-width: 930px) {
  .sipnotech__what-we-do {
    padding: 2rem;
  }

  .Sipnotech__what-we-do_heading {
    font-size: 2rem;
  }

  .sipnotech__what-we-do_cards {
    grid-template-columns: auto
  }

  .sipnotech__what-we-do_cards_desc {
    max-width: 18rem;
  }

  .sipnotech__what-we-do_cards-btn_icon {
    font-size: 1rem;
    top: 1.1rem;
    padding: 0rem 0.2rem;
  }

  .sipnotech__what-we-do_cards-card {
    padding: 1rem;
    overflow: hidden;
    height: 22rem;
    max-width: 22rem;
  }

  .sipnotech__what-we-do_cards_first-div, .sipnotech__what-we-do_cards_second-div, .sipnotech__what-we-do_cards_third-div {
    flex-direction: row;
    justify-content: center;
    gap: 0rem;
  }

  .sipnotech__what-we-do_people-img {
    display: none;

  }

  .sipnotech__what-we-do_people-img-mobile {
    display: block;
    margin-inline: auto;
    width: 40%;
  }
}

@media screen and (max-width: 650px) {
  .sipnotech__what-we-do {
    padding: 1rem 2rem;
    text-align: center;
  }

  .Sipnotech__what-we-do_heading {
    font-size: 2rem;
    line-height: 2.5rem;
    padding: 1rem 1rem;
  }

  .sipnotech__what-we-do_cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .sipnotech__what-we-do_cards_title {
    font-size: 1.5rem;
  }

  .sipnotech__what-we-do_cards_desc {
    font-size: 0.7rem;
  }

  .sipnotech__what-we-do_cards-card_img {
    width: 3rem;
  }

  .sipnotech__what-we-do_cards-card {
    padding: 1rem;
    overflow: hidden;
    height: 18rem;
    max-width: 18rem;
    margin-inline: auto;

  }

  .sipnotech__what-we-do_cards_first-div, .sipnotech__what-we-do_cards_second-div, .sipnotech__what-we-do_cards_third-div {
    flex-direction: column;
    justify-content: center;
    gap: 0rem;
  }

  .sipnotech__what-we-do_people-img {
    display: none;

  }
  .sipnotech__what-we-do_people-img-mobile {
    display: block;
    margin-inline: auto;
    width: 70%;
  }
}